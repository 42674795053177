import SurveyQuestionType from './SurveyQuestionType';

class SurveyQuestion {
  public questionId: string;

  public questionType: SurveyQuestionType;

  public title: string;

  public description: string;

  public group: string;

  public variability: string;

  public identifier: string;

  public info?: string;

  public suffix?: string;

  constructor(
    questionId: string,
    questionType: SurveyQuestionType,
    title: string,
    description: string,
    group: string,
    variability: string,
    identifier: string,
    info?: string,
    suffix?: string,
  ) {
    this.questionId = questionId;
    this.questionType = questionType;
    this.title = title;
    this.description = description;
    this.group = group;
    this.variability = variability;
    this.identifier = identifier;
    this.info = info;
    this.suffix = suffix;
  }

  public static parse(object: any) {
    return new SurveyQuestion(
      object.QuestionId,
      object.QuestionType,
      object.Title,
      object.Description,
      object.Group,
      object.Variability,
      object.Identifier,
      object.Info ?? undefined,
      object.Suffix ?? undefined,
    );
  }
}

export default SurveyQuestion;
