import ISurvey from '../interfaces/ISurvey';
import SurveyQuestionType from './SurveyQuestionType';
import SurveyQuestionMultipleChoice from './SurveyQuestionMultipleChoice';
import SurveyQuestionText from './SurveyQuestionText';
import SurveyQuestionNumericInput from './SurveyQuestionNumericInput';
import SurveyQuestionNumericSlider from './SurveyQuestionNumericSlider';
import SurveyChapter from './SurveyChapter';
import SurveyQuestion from './SurveyQuestion';
import SurveyTheme from './SurveyTheme';
import SurveyAdviceMapping from './SurveyAdviceMapping';

class Survey implements ISurvey {
  id: string;

  title: string;

  description: string;

  chapters: SurveyChapter[];

  questions: SurveyQuestion[];

  themes: SurveyTheme[];

  adviceMappings: SurveyAdviceMapping[];

  constructor(
    id: string,
    title: string,
    description: string,
    chapters: SurveyChapter[],
    questions: SurveyQuestion[],
    themes: SurveyTheme[],
    adviceMappings: SurveyAdviceMapping[],
  ) {
    this.id = id;
    this.title = title;
    this.description = description;
    this.chapters = chapters;
    this.questions = questions;
    this.themes = themes;
    this.adviceMappings = adviceMappings;
  }

  public static fromJson(object: any): Survey {
    let surveyQuestions: SurveyQuestion[] = [];

    object.chapters.forEach((chapter: SurveyChapter) => {
      const { questions, description } = chapter;

      const chapterQuestions = questions.map((question: any) => {
        const { QuestionType } = question;
        switch (QuestionType) {
          case SurveyQuestionType.TEXT:
            return SurveyQuestionText.parse(question);
          case SurveyQuestionType.MULTIPLE_CHOICE:
            return SurveyQuestionMultipleChoice.parse(question);
          case SurveyQuestionType.NUMERIC_INPUT:
            return SurveyQuestionNumericSlider.parse(question);
          case SurveyQuestionType.NUMERIC_SLIDER:
            return SurveyQuestionNumericInput.parse(question);
          default:
            throw new Error('This type is not yet supported');
        }
      });

      chapterQuestions.forEach((question: SurveyQuestion) => {
        const { title } = question;
        if (description) {
          // eslint-disable-next-line no-param-reassign
          question.title = `<span class="chapter-description">${description}:</span> ${title}`;
        }
      });

      surveyQuestions = surveyQuestions.concat(chapterQuestions);
    });

    const surveyAdviceMappings: SurveyAdviceMapping[] = [];
    object.themes.forEach((theme: SurveyTheme) => {
      theme.adviceMappings.forEach((adviceMapping: SurveyAdviceMapping) => {
        surveyAdviceMappings.push(adviceMapping);
      });
    });

    return new Survey(
      object.id,
      object.title,
      object.description,
      object.chapters,
      surveyQuestions,
      object.themes,
      surveyAdviceMappings,
    );
  }
}

export default Survey;
