import useBem from '@indicia/use-bem';

import ButtonTheme from './enums/ButtonTheme';
import IconPosition from './enums/IconPosition';
import IconRender from '../icon/IconRender';
import Icon from './enums/Icon';

import './Button.scss';

type PropType = {
  id?: string;
  active?: boolean;
  disabled?: boolean;
  icon?: Icon;
  iconPosition?: IconPosition;
  iconWidth?: string;
  iconHeight?: string;
  isSubmit?: boolean;
  label?: string;
  onClick: () => void;
  theme?: ButtonTheme;
  tooltip?: string;
};

const Button = ({
  id = '',
  active = false,
  disabled = false,
  icon = undefined,
  iconPosition = undefined,
  isSubmit = false,
  iconWidth = '15px',
  iconHeight = '15px',
  label = '',
  tooltip = '',
  onClick,
  theme = ButtonTheme.NORMAL,
}: PropType) => {
  const { bemClassName, bemClassNames, bemClassNameWithBase } = useBem('button');

  return (
    <button
      id={id}
      type={isSubmit ? 'submit' : 'button'}
      className={bemClassNames([
        bemClassNameWithBase(null, theme),
        bemClassName(null, 'disabled', disabled),
        bemClassName(null, 'active', active),
        bemClassName(null, `icon-${iconPosition}`, iconPosition !== undefined),
      ])}
      onClick={(e: any): void => {
        e.preventDefault();
        onClick();
      }}
      disabled={disabled}
      aria-label={label}
      title={tooltip}
    >
      {label && <span className={bemClassName('label')}>{label}</span>}
      {icon && (
        <IconRender
          className={bemClassName('icon')}
          icon={icon}
          width={iconWidth}
          height={iconHeight}
        />
      )}
    </button>
  );
};

export default Button;
