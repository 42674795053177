import classNames from 'classnames';

import './Loader.scss';

export type PropType = {
  large?: boolean;
};

const Loader = ({ large = false }: PropType) => (
  <div className={classNames('lds-ellipsis', { 'lds-ellipsis--large': large })}>
    <div />
    <div />
    <div />
    <div />
  </div>
);

export default Loader;
