import { useQuery, UseQueryResult } from '@tanstack/react-query';
import get from '../data/theme-service';
import { ThemePageType } from '../types';

const useTheme = (employerCode: string, theme: string): UseQueryResult<ThemePageType, Error> =>
  useQuery(['theme', theme], () => get(employerCode, theme), {
    staleTime: undefined,
  });

export default useTheme;
