import React, { useState } from 'react';
import useBem from '@indicia/use-bem';
import _ from 'lodash';
import { Slider, SliderChangeEvent, SliderLabel } from '@progress/kendo-react-inputs';

import './QuestionSlider.scss';

type QuestionSliderType = {
  minValue: number;
  maxValue: number;
  onChange: (value: number) => void;
  smallStepValue: number;
  previousAnswer?: number;
};

const QuestionSlider = (propType: QuestionSliderType) => {
  const { minValue, maxValue, onChange, smallStepValue, previousAnswer } = propType;
  const { bemClassNameWithBase } = useBem('slider-tick');
  const { bemClassName, bemClassNames } = useBem('question-slider');
  const [value, setValue] = useState<number | undefined>(previousAnswer);

  return (
    <div className={bemClassNames([bemClassName(), bemClassName(null, 'has-value', value !== undefined)])}>
      <div className={bemClassName('slider')}>
        <div className={bemClassName('container')}>
          <Slider
            value={value}
            step={smallStepValue}
            min={minValue}
            max={maxValue}
            defaultValue={previousAnswer}
            onChange={(event: SliderChangeEvent) => {
              const { value: v } = event;
              const newValue = Math.round(v);
              setValue(newValue);
              onChange(newValue);
            }}
            className={maxValue >= 20 ? 'minimize-ticks' : ''}
          >
            {_.range(minValue, maxValue + 1).map((tick) => (
              <SliderLabel
                position={tick}
                key={`tick_${tick}`}
              >
                <span className={bemClassNameWithBase(null, 'active', value !== undefined && tick < value)} />
                {tick}
              </SliderLabel>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

QuestionSlider.defaultProps = {
  previousAnswer: undefined,
};

export default QuestionSlider;
