import SurveyQuestionType from './SurveyQuestionType';
import SurveyQuestion from './SurveyQuestion';
import SurveyChoiceOption from './SurveyChoiceOption';
import RenderDirection from './RenderDirection';

class SurveyQuestionMultipleChoice extends SurveyQuestion {
  options: SurveyChoiceOption[];

  allowMultiSelect: boolean;

  renderDirection: RenderDirection;

  constructor(
    questionId: string,
    questionType: SurveyQuestionType,
    title: string,
    description: string,
    group: string,
    variability: string,
    identifier: string,
    options: SurveyChoiceOption[],
    allowMultiSelect: boolean,
    renderDirection: RenderDirection,
    info?: string,
    suffix?: string,
  ) {
    super(questionId, questionType, title, description, group, variability, identifier, info, suffix);

    this.options = options;
    this.allowMultiSelect = allowMultiSelect;
    this.renderDirection = renderDirection;
  }

  public static parse(object: any) {
    return new SurveyQuestionMultipleChoice(
      object.QuestionId,
      object.QuestionType,
      object.Title,
      object.Description,
      object.Group,
      object.Variability,
      object.Identifier,
      object.Options.map((option: {}) => SurveyChoiceOption.parse(option)),
      object.AllowMultiSelect,
      object.RenderDirection,
      object.Info || undefined,
      object.Suffix ?? undefined,
    );
  }
}

export default SurveyQuestionMultipleChoice;
