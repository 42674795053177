import Icon from '../components/button/enums/Icon';

const getIcon = (icon: string) => {
  switch (icon) {
    case 'Cholesterol':
    case 'Triglicerides':
    case 'Hdl':
      return Icon.cholesterol;
    case 'Bloeddruk':
    case 'Vitals':
      return Icon.vitalewaarden;
    case 'Nutrition':
    case 'Diet':
    case 'Voeding':
      return Icon.voeding;
    case 'Weight':
    case 'Gewicht':
      return Icon.gewicht;
    case 'Bmi':
      return Icon.vitalewaarden;
    case 'Smoking':
    case 'Roken':
      return Icon.roken;
    case 'Coffee':
    case 'Koffie':
      return Icon.koffie;
    case 'Exersize':
    case 'HyperTension':
    case 'Bewegen':
      return Icon.beweging;
    case 'Slapen':
    case 'Sleep':
      return Icon.slapen;
    case 'Stress':
      return Icon.stress;
    case 'Alcohol':
      return Icon.alcohol;
    default:
      throw new Error(`Icon not found for category: ${icon}`);
  }
};

export default getIcon;
