import useBem from '@indicia/use-bem';
import Slider from '@mui/material/Slider';

import { HabitSliderDetails } from './types';

type HabitSliderDetailsPropType = {
  onChange: (value: number) => void;
} & HabitSliderDetails;

const HabitSlider = ({ currentValue, minValue, maxValue, onChange, step, questionKey }: HabitSliderDetailsPropType) => {
  const { bemClassName } = useBem('habit__slider');

  return (
    <div className={bemClassName()}>
      <div className={bemClassName('label')}>{minValue}</div>
      <Slider
        id={questionKey}
        min={minValue}
        max={maxValue}
        value={currentValue}
        step={step || 1}
        onChange={(event: any) => {
          const {
            target: { value },
          } = event;
          const newValue = Math.round(value);
          onChange(newValue);
        }}
      />
      <div className={bemClassName('label')}>{maxValue}</div>
    </div>
  );
};

export default HabitSlider;
