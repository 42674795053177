import useBem from '@indicia/use-bem';

import RealAge from '../realAge/RealAge';
import TierList from '../tier/TierList';
import TierType from '../../TierType';
import { StatisticsType } from '../../types';

import './Statistics.scss';

const Statistics = ({ age, compliments, improvements, onInfoClick, realAge, sticky }: StatisticsType) => {
  const { bemClassName, bemClassNameWithBase } = useBem('statistics');
  return (
    <div className={bemClassNameWithBase(null, 'stick', sticky)}>
      <div className={bemClassName('content')}>
        <div className={bemClassName('real-age')}>
          <RealAge
            age={age}
            realAge={realAge}
            onInfoClick={onInfoClick}
          />
        </div>
        <TierList
          tier={TierType.GOOD}
          items={compliments}
        />
        <TierList
          tier={TierType.BAD}
          items={improvements}
        />
      </div>
    </div>
  );
};

export default Statistics;
