import React, { useState } from 'react';
import useBem from '@indicia/use-bem';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import './HeaderBar.scss';

const HeaderBar = () => {
  const { t } = useTranslation();
  const { bemClassName, bemClassNames } = useBem('header-bar');
  const [mobileMenuOpen, setMobileMenuOpen] = useState<boolean>(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  const token = localStorage.getItem('gezondleven-activation-code');

  return (
    <header className={bemClassNames([bemClassName(), bemClassName(null, 'show-menu', mobileMenuOpen)])}>
      <div className="container container--wide">
        <NavLink
          to={token ? `/${token}` : '/'}
          className={bemClassName('title')}
        >
          <h1>{t('generic.title')}</h1>
        </NavLink>
        <div
          className={bemClassNames([
            bemClassName('menu-mobile'),
            bemClassName('menu-mobile', 'active', mobileMenuOpen),
          ])}
        >
          <button
            className="hamburger-lines"
            onClick={() => toggleMobileMenu()}
            type="button"
          >
            <span className="line line1" />
            <span className="line line2" />
            <span className="line line3" />
          </button>
        </div>
        <div className={bemClassName('menu')}>
          <NavLink
            to="/about-us"
            className={bemClassName('menu-item')}
          >
            {t('generic.menu.about-us')}
          </NavLink>
          <NavLink
            to="/gezondleventest"
            className={bemClassName('menu-item')}
          >
            {t('generic.menu.about-test')}
          </NavLink>
          <NavLink
            to="/faq"
            className={bemClassName('menu-item')}
          >
            {t('generic.menu.faq')}
          </NavLink>
        </div>
      </div>
    </header>
  );
};

export default HeaderBar;
