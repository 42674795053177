import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useSurvey from '../../hooks/useSurvey';
import { useSurveyContext } from '../../store/SurveyContext';
import SurveyQuestion from '../../../../shared/models/survey/SurveyQuestion';
import { postAnswers } from '../../data/SurveyService';
import SurveyProgress from '../surveyProgress/SurveyProgress';
import SurveyNavigation from '../surveyNavigation/SurveyNavigation';
import SurveyQuestionRenderer from './SurveyQuestionRenderer';
import { useResultContext } from '../../../dashboard/store/ResultContext';
import { Result } from '../../../dashboard/types';
import LoaderContainer from '../../../../shared/components/loader/LoaderContainer';

const SurveyQuestions = () => {
  const {
    email,
    questionAnswers,
    questionNumber,
    setQuestionNumber,
    setBackupQuestionAnswers,
    setSurvey,
    setSessionKey,
    activationCode,
    sessionKey,
  } = useSurveyContext();
  const { t } = useTranslation();
  const navigator = useNavigate();

  const surveyId = 1;
  const { isLoading, data } = useSurvey(surveyId, activationCode);
  const { setResult } = useResultContext();

  const [question, setQuestion] = useState<SurveyQuestion | null>(null);
  const [loading, setLoading] = useState(false);
  const [resultLoading, setResultLoading] = useState(false);

  useEffect(() => {
    if (data?.specification) {
      const { specification: survey, sessionKey: sKey } = data!;
      const { questions } = survey;
      setQuestion(questions[questionNumber]);
      setSessionKey(sKey);
      setSurvey(survey);
    }
  }, [questionNumber, data]);

  if (isLoading && !question) {
    return <LoaderContainer text={t('survey.loading')} />;
  }

  const { specification: survey } = data!;

  const allQuestions: SurveyQuestion[] = survey!.questions;

  const checkDisabledButton = () => {
    if (loading) return true;

    const surveyAnswer = questionAnswers.find((answer) => answer.questionId === question?.questionId);
    if (surveyAnswer === undefined) return true;

    const { value } = surveyAnswer;

    if (Array.isArray(value)) {
      return value.length === 0;
    }

    return false;
  };

  const handleNext = () => {
    if (questionNumber < allQuestions.length) {
      setQuestionNumber(questionNumber + 1);
    }
  };

  const handlePrevious = () => {
    if (questionNumber > 0) {
      setQuestionNumber(questionNumber - 1);
    }
  };

  const handleFinish = () => {
    setLoading(true);
    setResultLoading(true);
    postAnswers(surveyId, questionAnswers, activationCode, true, email, sessionKey)
      .then((result: any) => {
        const { data: d } = result;
        setBackupQuestionAnswers(questionAnswers);
        setTimeout(() => {
          navigator('/dashboard');
          setResultLoading(false);
          setResult(d as Result);
        }, 2000);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  if (resultLoading) {
    return <LoaderContainer text={t('survey.loading-results')} />;
  }

  return (
    <>
      <SurveyProgress
        currentQuestion={questionNumber}
        totalQuestions={allQuestions.length}
      />
      {question && <SurveyQuestionRenderer question={question} />}
      <SurveyNavigation
        onHandlePrevious={handlePrevious}
        onHandleNext={handleNext}
        onHandleFinish={handleFinish}
        firstQuestion={questionNumber === 0}
        lastQuestion={questionNumber === allQuestions.length - 1}
        labelNext={t('survey.next')}
        nextDisabled={checkDisabledButton()}
        labelPrev={t('survey.back')}
      />
    </>
  );
};

export default SurveyQuestions;
