import ISurveyAnswer from '../interfaces/ISurveyAnswer';

class SurveyAnswer implements ISurveyAnswer {
  questionId: string;

  value?: number | string | string[];

  constructor(questionId: string, value?: number | string | string[]) {
    this.questionId = questionId;
    this.value = value;
  }

  public static fromJson(object: any): SurveyAnswer {
    return new SurveyAnswer(object.QuestionId, object.Value);
  }

  public static fromJsonList(object: any): SurveyAnswer[] {
    return object.map((json: any) => SurveyAnswer.fromJson(json));
  }
}

export default SurveyAnswer;
