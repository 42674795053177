import * as React from "react";
import { useTranslation } from "react-i18next";

import Button from "../../../shared/components/button/Button";

import "./ErrorHandling.scss";

const NotFound = () => {
  const { t } = useTranslation();

  return (
    <div className="content-container">
      <div className="error-handling">
        <h1>{t("error.not-found.title")}</h1>
        <p>{t("error.not-found.message")}</p>
        <div className="buttons">
          <Button
            onClick={() => {
              window.location.href = "/dashboard";
            }}
            label={t("error.not-found.to-dashboard")!}
          />
        </div>
      </div>
    </div>
  );
};

export default NotFound;
