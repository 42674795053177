import { FC } from 'react';
import IconRender from '../../../../shared/components/icon/IconRender';
import Icon from '../../../../shared/components/button/enums/Icon';
import { LinkType } from '../../types';

import './theme-link.scss';

const ThemeLink: FC<LinkType> = ({ linkUrl, linkText }) => (
  <a
    className="theme-link"
    href={linkUrl}
    title={linkText}
    rel="noreferrer"
    target="_blank"
  >
    {linkText}
    <IconRender
      icon={Icon.arrowRight}
      color="currentColor"
    />
  </a>
);

export default ThemeLink;
