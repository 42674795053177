import { ReactNode } from 'react';
import useBem from '@indicia/use-bem';

import './Dialog.scss';

type DialogPropType = {
  content: ReactNode;
  title?: string;
  onClose: () => void;
  actions?: ReactNode;
};

const Dialog = ({ content, onClose, title = undefined, actions = null }: DialogPropType) => {
  const { bemClassName } = useBem('dialog');

  return (
    <div className={bemClassName()}>
      <button
        className={bemClassName('overlay')}
        type="button"
        onClick={onClose}
      >
        Scherm sluiten
      </button>
      <dialog
        className={bemClassName('popup')}
        open
      >
        {title && <h2 className={bemClassName('title')}>{title}</h2>}
        <div className={bemClassName('content')}>{content}</div>
        {actions && <div className={bemClassName('footer')}>{actions}</div>}
      </dialog>
    </div>
  );
};

export default Dialog;
