import SurveyQuestion from '../../../../shared/models/survey/SurveyQuestion';
import { HabitNumericInputDetails, HabitRadioDetails, HabitSliderDetails } from './types';
import HabitType from './HabitType';
import SurveyQuestionType from '../../../../shared/models/survey/SurveyQuestionType';
import SurveyQuestionNumericSlider from '../../../../shared/models/survey/SurveyQuestionNumericSlider';
import SurveyQuestionMultipleChoice from '../../../../shared/models/survey/SurveyQuestionMultipleChoice';
import SurveyQuestionNumericInput from '../../../../shared/models/survey/SurveyQuestionNumericInput';
import Habit from './Habit';
import SurveyAnswer from '../../../../shared/models/survey/SurveyAnswer';
import { useSurveyContext } from '../../../survey/store/SurveyContext';

type PropType = {
  question: SurveyQuestion;
  setQuestionAnswer: (questionAnswer: SurveyAnswer) => void;
};

const HabitRenderer = ({ question, setQuestionAnswer }: PropType) => {
  const { questionAnswers } = useSurveyContext();
  const getCurrentValue = (questionId: string) => {
    const q = questionAnswers.find((answer) => {
      const { questionId: answerQuestionId } = answer;
      return answerQuestionId === questionId;
    });
    const { value } = q!;
    if (Array.isArray(value)) {
      return value[0];
    }
    return value;
  };

  const { questionId, questionType, title } = question;
  const currentValue = getCurrentValue(questionId);
  let valueDetails: HabitSliderDetails | HabitRadioDetails | HabitNumericInputDetails = {
    minValue: 0,
    maxValue: 10,
    currentValue: 0,
    questionKey: questionId,
  };
  let type = HabitType.INPUT;

  if (questionType === SurveyQuestionType.NUMERIC_SLIDER) {
    const { max, min } = question as SurveyQuestionNumericSlider;
    valueDetails = {
      minValue: min,
      maxValue: max,
      currentValue: currentValue as number,
      step: 1,
      questionKey: questionId,
    };
    type = HabitType.SLIDER;
  }
  if (questionType === SurveyQuestionType.MULTIPLE_CHOICE) {
    const { options } = question as SurveyQuestionMultipleChoice;
    valueDetails = {
      options,
      questionKey: questionId,
      currentValue: currentValue as string,
    };
    type = HabitType.RADIO;
  }
  if (questionType === SurveyQuestionType.NUMERIC_INPUT) {
    const { max, min } = question as SurveyQuestionNumericInput;
    valueDetails = {
      minValue: min,
      maxValue: max,
      currentValue: currentValue as number,
      questionKey: questionId,
    };
    type = HabitType.INPUT;
  }

  return (
    <Habit
      key={questionId}
      onChange={(answer: SurveyAnswer) => {
        setQuestionAnswer(answer);
      }}
      questionId={questionId}
      title={title}
      type={type}
      valueDetails={valueDetails}
    />
  );
};

export default HabitRenderer;
