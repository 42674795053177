import { Trans, useTranslation } from 'react-i18next';
import useBem from '@indicia/use-bem';

import Button from '../../../shared/components/button/Button';
import ButtonTheme from '../../../shared/components/button/enums/ButtonTheme';
import { getFileFromStorage } from '../../../shared/utils/DownloadFile';

const AboutTest = () => {
  const { bemClassName, bemClassNames } = useBem('content-page');
  const { t } = useTranslation();

  const downloadAlgemeneVoorwaarden = () => {
    getFileFromStorage(
      window.__RUNTIME_CONFIG__.ALGEMENE_VOORWAARDEN_URL,
      window.__RUNTIME_CONFIG__.ALGEMENE_VOORWAARDEN_NAME,
    );
  };

  const downloadPrivacyStatement = () => {
    getFileFromStorage(
      window.__RUNTIME_CONFIG__.PRIVACY_STATEMENT_URL,
      window.__RUNTIME_CONFIG__.PRIVACY_STATEMENT_NAME,
    );
  };

  return (
    <div className={bemClassNames([bemClassName(), 'background-image'])}>
      <div className="container">
        <div className={bemClassName('title')}>
          <h1>{t('about-test.title')}</h1>
        </div>
        <div className={bemClassName('content')}>
          <Trans
            i18nKey="about-test.content"
            components={{
              p: <p />,
              p1: <p className="large" />,
              b: <strong />,
              i: <i />,
              ul: <ul />,
              li: <li />,
              lifelinesLink: (
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.lifelines.nl/"
                >
                  {' '}
                </a>
              ),
              algemeneVoorwaardenLink: (
                <Button
                  onClick={() => downloadAlgemeneVoorwaarden()}
                  label={t('generic.algemene-voorwaarden-small')!}
                  theme={ButtonTheme.TEXT}
                />
              ),
              privacyStatementLink: (
                <Button
                  onClick={() => downloadPrivacyStatement()}
                  label={t('generic.privacy-statement-small')!}
                  theme={ButtonTheme.TEXT}
                />
              ),
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default AboutTest;
