import { useEffect, useState } from 'react';
import useBem from '@indicia/use-bem';
import { useNavigate, useParams } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { Input } from '@progress/kendo-react-inputs';

import Button from '../../../shared/components/button/Button';
import { useSurveyContext } from '../store/SurveyContext';
import { getFileFromStorage } from '../../../shared/utils/DownloadFile';
import ButtonTheme from '../../../shared/components/button/enums/ButtonTheme';
import Dialog from '../../../shared/components/dialog/Dialog';

import './StartSurvey.scss';

const StartSurvey = () => {
  const { activationCode } = useParams();
  const { bemClassName, bemClassNames } = useBem('survey-start');
  const [agreement, setAgreement] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const navigator = useNavigate();
  const { t } = useTranslation();
  const { setEmailAddress, removeAnswers, setActivationCode } = useSurveyContext();

  useEffect(() => {
    setActivationCode(activationCode || '');
  });

  const handleStartSurvey = () => {
    removeAnswers();
    setEmailAddress(email);
    navigator('/survey');
  };

  const downloadAlgemeneVoorwaarden = () => {
    getFileFromStorage(
      window.__RUNTIME_CONFIG__.ALGEMENE_VOORWAARDEN_URL,
      window.__RUNTIME_CONFIG__.ALGEMENE_VOORWAARDEN_NAME,
    );
  };

  const downloadPrivacyStatement = () => {
    getFileFromStorage(
      window.__RUNTIME_CONFIG__.PRIVACY_STATEMENT_URL,
      window.__RUNTIME_CONFIG__.PRIVACY_STATEMENT_NAME,
    );
  };

  const handleOnChange = () => {
    if (!agreement) {
      setShowDialog(true);
    } else {
      setAgreement(false);
    }
  };

  const validActivationCode = activationCode !== undefined && activationCode !== null;

  return (
    <>
      <div className={bemClassNames([bemClassName(), 'background-image'])}>
        <div className="container">
          <div className={bemClassName('column')}>
            <h1>{t('start.title')}</h1>
            <h2>{t('start.subtitle')}</h2>
            <Trans
              i18nKey="start.text"
              components={{ p: <p />, b: <strong /> }}
            />
            {!validActivationCode && (
              <div className={bemClassName('no-code')}>
                <Trans
                  i18nKey="start.no-activation-code"
                  components={{ p: <p /> }}
                />
              </div>
            )}
            {validActivationCode && (
              <>
                <div className={bemClassName('privacy')}>
                  <input
                    type="checkbox"
                    id="privacy-checkbox"
                    name="privacy-checkbox"
                    checked={agreement}
                    onChange={() => handleOnChange()}
                  />
                  <label htmlFor="privacy-checkbox">
                    <Trans
                      i18nKey="start.privacy-statement"
                      components={{
                        p: <p />,
                        br: <br />,
                        b: <strong />,
                        algemeneVoorwaardenLink: (
                          <Button
                            onClick={() => downloadAlgemeneVoorwaarden()}
                            label={t('generic.algemene-voorwaarden')!}
                            theme={ButtonTheme.TEXT}
                          />
                        ),
                        privacyStatementLink: (
                          <Button
                            onClick={() => downloadPrivacyStatement()}
                            label={t('generic.privacy-statement')!}
                            theme={ButtonTheme.TEXT}
                          />
                        ),
                      }}
                    />
                  </label>
                </div>

                <Input
                  type="text"
                  value={email}
                  placeholder={t('start.email-placeholder')!}
                  onChange={(e) => {
                    const {
                      target: { value },
                    } = e;
                    setEmail(value as string);
                  }}
                />
              </>
            )}
          </div>
          <div className={bemClassNames([bemClassName('column'), bemClassName('column', 'reverse')])}>
            {validActivationCode && (
              <Button
                disabled={!agreement}
                onClick={handleStartSurvey}
                label={t('survey.start')!}
              />
            )}
          </div>
        </div>
      </div>
      {showDialog && (
        <Dialog
          onClose={() => setShowDialog(false)}
          content={
            <Trans
              i18nKey="toestemmingsverklaring.content"
              components={{
                p: <p />,
                ul: <ul />,
                li: <li />,
                b: <strong />,
                algemeneVoorwaardenLink: (
                  <Button
                    onClick={() => downloadAlgemeneVoorwaarden()}
                    label={t('generic.algemene-voorwaarden-small')!}
                    theme={ButtonTheme.TEXT}
                  />
                ),
                privacyStatementLink: (
                  <Button
                    onClick={() => downloadPrivacyStatement()}
                    label={t('generic.privacy-statement-small')!}
                    theme={ButtonTheme.TEXT}
                  />
                ),
              }}
            />
          }
          actions={
            <>
              <Button
                onClick={() => setShowDialog(false)}
                label={t('toestemmingsverklaring.cancel')!}
                theme={ButtonTheme.TEXT}
              />
              <Button
                onClick={() => {
                  setAgreement(true);
                  setShowDialog(false);
                }}
                label={t('toestemmingsverklaring.accept')!}
                theme={ButtonTheme.NORMAL}
              />
            </>
          }
          title={t('toestemmingsverklaring.title')!}
        />
      )}
    </>
  );
};

export default StartSurvey;
