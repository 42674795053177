import useBem from '@indicia/use-bem';
import { Trans, useTranslation } from 'react-i18next';

const AboutUs = () => {
  const { bemClassName, bemClassNames } = useBem('content-page');
  const { t } = useTranslation();

  return (
    <div className={bemClassNames([bemClassName(), 'background-image'])}>
      <div className="container">
        <div className={bemClassName('title')}>
          <h1>{t('about-us.title')}</h1>
        </div>
        <div className={bemClassName('content')}>
          <Trans
            i18nKey="about-us.content"
            components={{
              p: <p />,
              p1: <p className="large" />,
              b: <strong />,
              i: <i />,
              zkLink: (
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.zilverenkruis.nl/consumenten"
                >
                  {' '}
                </a>
              ),
              emailLink: (
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="mailto:mirande.groener@zilverenkruis.nl"
                >
                  {' '}
                </a>
              ),
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
