import ISurveyRequestResponse from '../interfaces/ISurveyRequestResponse';
import SurveyAnswer from './SurveyAnswer';
import Survey from './Survey';

class SurveyRequestResponse implements ISurveyRequestResponse {
  answers: SurveyAnswer[];

  sessionKey: string;

  specification: Survey;

  constructor(answers: SurveyAnswer[], surveyKey: string, specification: Survey) {
    this.answers = answers;
    this.sessionKey = surveyKey;
    this.specification = specification;
  }

  public static parse(object: any) {
    return new SurveyRequestResponse(
      SurveyAnswer.fromJsonList(object.answers),
      object.sessionKey,
      Survey.fromJson(object.specification),
    );
  }
}

export default SurveyRequestResponse;
