import useBem from '@indicia/use-bem';
import { Trans, useTranslation } from 'react-i18next';

const Faq = () => {
  const { bemClassName, bemClassNames } = useBem('content-page');
  const { t } = useTranslation();

  return (
    <div className={bemClassNames([bemClassName(), 'background-image'])}>
      <div className="container">
        <div className={bemClassName('title')}>
          <h1>{t('faq.title')}</h1>
        </div>
        <div className={bemClassName('content')}>
          <Trans
            i18nKey="faq.content"
            components={{
              p: <p />,
              p1: <p className="large" />,
              b: <strong />,
              i: <i />,
              ul: <ul />,
              li: <li />,
              hartstichtingLink: (
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.hartstichting.nl/oorzaken/bloeddruk/bloeddruk-meten/bloeddrukmeter-kopen"
                >
                  {' '}
                </a>
              ),
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Faq;
