import React, { useState } from 'react';
import { NumericTextBox } from '@progress/kendo-react-inputs';

import '../SurveyQuestion.scss';
import { SurveySliderQuestionType } from '../SurveyQuestionTypes';
import SurveyAnswer from '../../../../shared/models/survey/SurveyAnswer';

type PropType = SurveySliderQuestionType & {
  id: string;
  onSelectAnswer: (answer: SurveyAnswer) => void;
  previousAnswer: number | undefined;
  bem: (element: string) => string;
};

const SurveyInputQuestion = ({ bem, id, minValue, maxValue, onSelectAnswer, previousAnswer, suffix }: PropType) => {
  const [selectedAnswer, setSelectedAnswer] = useState<number | undefined>(previousAnswer ?? minValue);

  const handleOnChange = (v: number) => {
    setSelectedAnswer(v);

    onSelectAnswer({
      value: v,
      questionId: id,
    });
  };

  return (
    <div className={bem('numeric')}>
      <NumericTextBox
        min={minValue}
        max={maxValue}
        value={selectedAnswer}
        onChange={(event) => {
          const { value } = event;
          handleOnChange(value as number);
        }}
        aria-labelledby={id}
      />
      {suffix && <p className={bem('suffix')}>{suffix}</p>}
    </div>
  );
};

export default SurveyInputQuestion;
