import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';

import './i18n';
import router from './routes';

import '@progress/kendo-theme-default/dist/all.css';
import './shared/styling/global.scss';
import './shared/styling/variables.scss';
import './shared/styling/typography.scss';
import './index.scss';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <StrictMode>
    <RouterProvider router={router} />
  </StrictMode>,
);
