import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { getSurvey } from '../data/SurveyService';
import SurveyRequestResponse from '../../../shared/models/survey/SurveyRequestResponse';

const useSurvey = (surveyId: number, activationCode: string): UseQueryResult<SurveyRequestResponse, Error> =>
  useQuery(['survey', surveyId], () => getSurvey(surveyId, activationCode), {
    staleTime: undefined,
  });

export default useSurvey;
