import useBem from '@indicia/use-bem';

import { HabitRadioDetails } from './types';
import SurveyChoiceOption from '../../../../shared/models/survey/SurveyChoiceOption';

type HabitRadioDetailsPropType = {
  onChange: (value: string) => void;
} & HabitRadioDetails;

const HabitRadio = ({ currentValue, questionKey, options, onChange }: HabitRadioDetailsPropType) => {
  const { bemClassName, bemClassNames } = useBem('habit__radio');

  return (
    <div className={bemClassName()}>
      {options.map(({ id, description }: SurveyChoiceOption) => (
        <button
          key={`${questionKey}_${id}`}
          id={`${questionKey}_${id}`}
          type="button"
          className={bemClassNames([bemClassName('option'), bemClassName('option', 'active', currentValue === id)])}
          onClick={() => onChange(id)}
        >
          {description}
        </button>
      ))}
    </div>
  );
};

export default HabitRadio;
