import SurveyQuestionType from './SurveyQuestionType';
import SurveyQuestion from './SurveyQuestion';

class SurveyQuestionNumericInput extends SurveyQuestion {
  min: number;

  max: number;

  constructor(
    questionId: string,
    questionType: SurveyQuestionType,
    title: string,
    description: string,
    group: string,
    variability: string,
    identifier: string,
    min: number,
    max: number,
    info?: string,
    suffix?: string,
  ) {
    super(questionId, questionType, title, description, group, variability, identifier, info, suffix);

    this.min = min;
    this.max = max;
  }

  public static parse(object: any) {
    return new SurveyQuestionNumericInput(
      object.QuestionId,
      object.QuestionType,
      object.Title,
      object.Description,
      object.Group,
      object.Variability,
      object.Identifier,
      object.Min,
      object.Max,
      object.Info ?? undefined,
      object.Suffix ?? undefined,
    );
  }
}

export default SurveyQuestionNumericInput;
