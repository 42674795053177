import { useTranslation } from 'react-i18next';
import useBem from '@indicia/use-bem';

import TierItem from './TierItem';
import { TierList as TierListType } from '../../types';
import Icon from '../../../../shared/components/button/enums/Icon';

import './TierList.scss';

const TierList = ({ items, tier }: TierListType) => {
  const { t } = useTranslation();
  const { bemClassName } = useBem('tier-list');

  const getIcon = (habit: string) => {
    switch (habit) {
      case 'Cholesterol':
        return Icon.vitalewaarden;
      case 'Triglicerides':
        return Icon.vitalewaarden;
      case 'Hdl':
        return Icon.vitalewaarden;
      case 'CopdMother':
        return Icon.alcohol;
      case 'CopdFather':
        return Icon.alcohol;
      case 'T2DMother':
        return Icon.alcohol;
      case 'CvdMother':
        return Icon.alcohol;
      case 'Diet':
        return Icon.voeding;
      case 'Education':
        return Icon.alcohol;
      case 'HyperTension':
        return Icon.vitalewaarden;
      case 'Bmi':
        return Icon.vitalewaarden;
      case 'PaidWork':
        return Icon.alcohol;
      case 'Smoking':
        return Icon.roken;
      case 'Coffee':
        return Icon.koffie;
      case 'Exersize':
        return Icon.beweging;
      case 'Sleep':
        return Icon.slapen;
      case 'Stress':
        return Icon.stress;
      case 'Age':
        return Icon.alcohol;
      case 'Sex':
        return Icon.alcohol;
      case 'Alcohol':
      default:
        return Icon.alcohol;
    }
  };

  return (
    <div className={bemClassName()}>
      <div className={bemClassName('title')}>{t(`dashboard.tier-list.title.${tier}`)}</div>
      <div className={bemClassName('items')}>
        {items.map((item: string) => (
          <TierItem
            key={item}
            icon={getIcon(item)}
            link={`/theme/${item}`}
            name={item}
            isPositive={tier === 'GOOD'}
          />
        ))}
      </div>
    </div>
  );
};

export default TierList;
