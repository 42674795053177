import Loader from './Loader';

type PropType = {
  text: string;
};

const LoaderContainer = ({ text }: PropType) => (
  <div className="loader-container">
    <Loader large />
    {text}
  </div>
);

export default LoaderContainer;
