import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useBem from '@indicia/use-bem';
import SurveyQuestionType from '../../../../shared/models/survey/SurveyQuestionType';
import SurveyQuestionNumericSlider from '../../../../shared/models/survey/SurveyQuestionNumericSlider';
import SurveySliderQuestion from '../surveySlider/SurveySliderQuestion';
import SurveyAnswer from '../../../../shared/models/survey/SurveyAnswer';
import SurveyInputQuestion from '../surveyInput/SurveyInputQuestion';
import SurveyQuestionMultipleChoice from '../../../../shared/models/survey/SurveyQuestionMultipleChoice';
import SurveyMultipleChoiceQuestion from '../surveyMultipleChoice/SurveyMultipleChoiceQuestion';
import SurveyQuestion from '../../../../shared/models/survey/SurveyQuestion';
import { useSurveyContext } from '../../store/SurveyContext';
import QuestionTitle from '../questionTitle/QuestionTitle';
import Dialog from '../../../../shared/components/dialog/Dialog';

type SurveyQuestionPropType = {
  question: SurveyQuestion;
};

const SurveyQuestionRenderer = ({ question }: SurveyQuestionPropType) => {
  const { bemClassName } = useBem('survey-question');
  const { t } = useTranslation();
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const { questionAnswers, setQuestionAnswer } = useSurveyContext();
  const { questionType, description, questionId, title, info, suffix } = question;

  const handleAnswer = (answer: SurveyAnswer) => {
    setQuestionAnswer(answer);
  };

  const objIndex = questionAnswers.findIndex((obj) => obj.questionId === questionId);
  const previousAnswer = objIndex !== -1 ? questionAnswers[objIndex] : null;

  const renderQuestionType = () => {
    if (questionType === SurveyQuestionType.NUMERIC_SLIDER) {
      const { max, min } = question as SurveyQuestionNumericSlider;

      return (
        <SurveySliderQuestion
          key={questionId}
          questionKey={questionId}
          minValue={min}
          maxValue={max}
          question={title}
          onAnswerSelect={(answer: SurveyAnswer) => handleAnswer(answer)}
          previousAnswer={(previousAnswer?.value as number) ?? undefined}
          bem={bemClassName}
        />
      );
    }

    if (questionType === SurveyQuestionType.NUMERIC_INPUT) {
      const { max, min } = question as SurveyQuestionNumericSlider;

      return (
        <SurveyInputQuestion
          question={title}
          key={questionId}
          id={questionId}
          minValue={min}
          maxValue={max}
          suffix={suffix}
          onSelectAnswer={(answer: SurveyAnswer) => handleAnswer(answer)}
          previousAnswer={(previousAnswer?.value as number) ?? undefined}
          bem={bemClassName}
        />
      );
    }

    const { options, renderDirection } = question as SurveyQuestionMultipleChoice;

    return (
      <SurveyMultipleChoiceQuestion
        key={questionId}
        id={questionId}
        onAnswerSelect={(answer: SurveyAnswer) => handleAnswer(answer)}
        previousAnswer={(previousAnswer?.value as string[]) ?? undefined}
        question={title}
        options={options}
        bem={bemClassName}
        renderDirection={renderDirection}
      />
    );
  };

  return (
    <div className={bemClassName()}>
      <div className={bemClassName('question')}>
        <div className={bemClassName('title')}>
          <QuestionTitle
            title={title}
            questionId={questionId}
          />
        </div>
        <div
          className={bemClassName('description')}
          /* eslint-disable-next-line react/no-danger */
          dangerouslySetInnerHTML={{ __html: description }}
        />
        {info && (
          <button
            type="button"
            className={bemClassName('info-button')}
            onClick={() => setShowDialog(true)}
            title={t('survey.info')!}
          >
            <span>i</span>
            {t('survey.info')}
          </button>
        )}
      </div>
      <div className={bemClassName('answers')}>{renderQuestionType()}</div>
      {showDialog && (
        <Dialog
          content={<p>{info}</p>}
          onClose={() => setShowDialog(false)}
        />
      )}
    </div>
  );
};

export default SurveyQuestionRenderer;
