import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import useBem from '@indicia/use-bem';

import { SolutionType } from '../../types';
import ThemeLink from '../theme-link/theme-link';

const ThemeZkHelp: FC<SolutionType> = ({ title, image, description, externalLink, androidLink, iosLink }) => {
  const { t } = useTranslation();
  const { bemClassName } = useBem('zk-help-item');

  return (
    <div className={bemClassName()}>
      <img
        src={image}
        alt={t('generic.image-for', { title })!}
      />
      <div className={bemClassName('content')}>
        <h4>{title}</h4>
        {/* eslint-disable-next-line react/no-danger */}
        <p dangerouslySetInnerHTML={{ __html: description }} />
        <div className={bemClassName('links')}>
          {androidLink || iosLink ? (
            <div className={bemClassName('stores')}>
              {androidLink && (
                <a
                  href={androidLink.linkUrl}
                  title="Play store"
                >
                  <img
                    src="/images/logos/play-store.png"
                    alt="Play Store"
                    height={40}
                    width={120}
                  />
                </a>
              )}
              {iosLink && (
                <a
                  href={iosLink.linkUrl}
                  title="Apple Store"
                >
                  <img
                    src="/images/logos/app-store.svg"
                    alt="Apple Store"
                    height={40}
                    width={120}
                  />
                </a>
              )}
            </div>
          ) : (
            <> </>
          )}
          {externalLink && <ThemeLink {...externalLink} />}
        </div>
      </div>
    </div>
  );
};

export default ThemeZkHelp;
