import useBem from '@indicia/use-bem';
import { NavLink } from 'react-router-dom';

import IconRender from '../../../../shared/components/icon/IconRender';
import { TierItem as TierItemType } from '../../types';
import { useSurveyContext } from '../../../survey/store/SurveyContext';
import Icon from '../../../../shared/components/button/enums/Icon';

const TierItem = ({ icon, name, isPositive }: TierItemType) => {
  const { getAdvice, getLink } = useSurveyContext();
  const { bemClassName } = useBem('tier-item');

  return (
    <div className={bemClassName()}>
      <div className={bemClassName('icon')}>
        <IconRender
          height="28px"
          width="28px"
          icon={icon}
          color="var(--zk-dark-blue)"
        />
      </div>
      <NavLink
        to={getLink(name)}
        className={bemClassName('name')}
        id={`statistic-link-${name}`}
      >
        {getAdvice(name, isPositive)}
        <IconRender
          icon={Icon.arrowRight}
          color="currentColor"
        />
      </NavLink>
    </div>
  );
};

export default TierItem;
