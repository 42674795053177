import SurveyQuestions from '../components/surveyQuestions/SurveyQuestions';

import './Survey.scss';

const SurveyPage = () => (
  <div className="background-image">
    <div className="survey-page container container--wide">
      <SurveyQuestions />
    </div>
  </div>
);
export default SurveyPage;
