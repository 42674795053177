import { FC } from 'react';
import useBem from '@indicia/use-bem';

import { SolutionsContainerType } from '../../types';
import ThemeTipsItem from './theme-tips-item';
import BackgroundContainer from '../../../../shared/components/background-container/background-container';

import './theme-tips.scss';

const ThemeTips: FC<SolutionsContainerType> = ({ title, image, description, solutions }) => {
  const { bemClassName, bemClassNames } = useBem('theme-tips');

  return (
    <BackgroundContainer type="tips">
      <div className={bemClassNames([bemClassName(), 'container'])}>
        <div className={bemClassName('tip-intro')}>
          <div className={bemClassName('tip-intro-bottom-layer')} />
          <div className={bemClassName('tip-intro-top-layer')}>
            <div className={bemClassName('tip-intro-top-layer-content')}>
              <h2>{title}</h2>
              <img
                src={image}
                alt="placeholder"
                width={412}
              />
            </div>
          </div>
        </div>
        <div className={bemClassName('tip-list')}>
          <div className={bemClassName('tip-description')}>{description}</div>
          <div className={bemClassName('tips')}>
            {solutions.map((solution, index) => {
              const { title: solutionTitle } = solution;

              return (
                <ThemeTipsItem
                  key={solutionTitle}
                  className={bemClassName('tip')}
                  index={index}
                  {...solution}
                />
              );
            })}
          </div>
        </div>
      </div>
    </BackgroundContainer>
  );
};

export default ThemeTips;
