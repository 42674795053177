import React, { useState } from 'react';

import '../SurveyQuestion.scss';
import { SurveyMultipleChoiceQuestionType } from '../SurveyQuestionTypes';
import SurveyAnswer from '../../../../shared/models/survey/SurveyAnswer';
import Button from '../../../../shared/components/button/Button';
import ButtonTheme from '../../../../shared/components/button/enums/ButtonTheme';
import SurveyChoiceOption from '../../../../shared/models/survey/SurveyChoiceOption';
import RenderDirection from '../../../../shared/models/survey/RenderDirection';

type PropType = SurveyMultipleChoiceQuestionType & {
  id: string;
  onAnswerSelect: (answer: SurveyAnswer) => void;
  options: SurveyChoiceOption[];
  bem: (element: string | null, modifier: string | null, condition: boolean) => string;
  previousAnswer: string[];
  renderDirection: RenderDirection;
};

const SurveyMultipleChoiceQuestion = ({
  bem,
  id,
  options,
  onAnswerSelect,
  previousAnswer,
  renderDirection,
}: PropType) => {
  const [selectedAnswers, setSelectedAnswers] = useState<string[]>(previousAnswer || []);
  const handleClick = (optionId: string) => {
    const index = selectedAnswers.indexOf(optionId);
    let answers: string[] = [];
    if (index !== -1) {
      answers = [];
    } else {
      answers = [optionId];
    }

    setSelectedAnswers(answers);
    onAnswerSelect({
      value: answers,
      questionId: id,
    });
  };

  return (
    <div
      className={`${bem('multiple-choice', null, true)} ${bem(
        'multiple-choice',
        'vertical',
        renderDirection === RenderDirection.Vertical,
      )}`}
    >
      {options.map(({ description, id: optionId }) => (
        <Button
          active={selectedAnswers.includes(optionId)}
          key={optionId}
          label={description}
          theme={ButtonTheme.QUESTION}
          onClick={() => handleClick(optionId)}
        />
      ))}
    </div>
  );
};

export default SurveyMultipleChoiceQuestion;
