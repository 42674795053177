import useBem from '@indicia/use-bem';
import { useTranslation } from 'react-i18next';

import { RealAge as RealAgeType } from '../../types';

import './RealAge.scss';

type RealAgePropType = RealAgeType & {
  onInfoClick: () => void;
};

const RealAge = ({ age, realAge, onInfoClick }: RealAgePropType) => {
  const { t } = useTranslation();
  const { bemClassName } = useBem('real-age');

  return (
    <div className={bemClassName()}>
      <div className={bemClassName('title')}>{t('dashboard.real-age.title')}</div>
      <div className={bemClassName('age')}>
        <div className={bemClassName('age__calculated')}>{realAge}</div>
        <div className={bemClassName('age__label')}>
          {t('dashboard.real-age.year')}
          <button
            type="button"
            className="information"
            onClick={onInfoClick}
            id="real-age-info-button"
          >
            i
          </button>
        </div>
      </div>
      <div className={bemClassName('description')}>{t('dashboard.real-age.description', { age })}</div>
    </div>
  );
};

export default RealAge;
