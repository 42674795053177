import useBem from '@indicia/use-bem';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import HabitRenderer from '../habit/HabitRenderer';
import IconRender from '../../../../shared/components/icon/IconRender';
import { SurveyQuestionCategory } from '../../types';
import getIcon from '../../../../shared/utils/get-icon';
import Icon from '../../../../shared/components/button/enums/Icon';
import { useSurveyContext } from '../../../survey/store/SurveyContext';

type PropType = {
  category: SurveyQuestionCategory;
  setQuestionAnswer: (questionAnswer: any) => void;
};

const Category = ({ category: { category, questions, renderDirection }, setQuestionAnswer }: PropType) => {
  const { t } = useTranslation();
  const { bemClassName, bemClassNames } = useBem('dashboard');
  const { getThemeName, getLink } = useSurveyContext();

  return (
    <div
      key={category}
      className={bemClassName('habits-container')}
    >
      <div className={bemClassName('habits-container__header')}>
        <div className={bemClassName('habits-container__header__icon')}>
          <div
            className={bemClassNames([
              bemClassName('habits-container__header__icon__container'),
              bemClassName('habits-container__header__icon__container', 'desktop'),
            ])}
          >
            <IconRender
              icon={getIcon(category)}
              width="50"
              height="50"
              color="var(--zk-dark-blue)"
            />
          </div>
          <div
            className={bemClassNames([
              bemClassName('habits-container__header__icon__container'),
              bemClassName('habits-container__header__icon__container', 'mobile'),
            ])}
          >
            <IconRender
              icon={getIcon(category)}
              width="30"
              height="30"
              color="var(--zk-blue)"
            />
          </div>
        </div>
        <NavLink
          to={getLink(category)}
          id={`theme-header-link-${category}`}
        >
          <h2>
            {getThemeName(category)}
            <IconRender
              icon={Icon.arrowRight}
              color="currentColor"
            />
          </h2>
        </NavLink>
      </div>
      <div className={bemClassNames([bemClassName('habits'), bemClassName('habits', renderDirection.toLowerCase())])}>
        {questions.map((question) => (
          <HabitRenderer
            key={question.questionId}
            question={question}
            setQuestionAnswer={setQuestionAnswer}
          />
        ))}
      </div>
      <div className={bemClassName('habits-container__footer')}>
        <NavLink
          to={getLink(category)}
          type="button"
          id={`theme-link-${category}`}
        >
          <IconRender
            icon={Icon.suggestion}
            color="currentColor"
          />
          {t(`dashboard.more.${category}`)}
          <IconRender
            icon={Icon.arrowRight}
            color="currentColor"
          />
        </NavLink>
      </div>
    </div>
  );
};

export default Category;
