import http from './HttpClient';

const downloadFile = (file: Blob, fileName: string) => {
  const aElement = document.createElement('a');
  aElement.setAttribute('download', fileName);
  const href = URL.createObjectURL(file);
  aElement.href = href;
  aElement.setAttribute('target', '_blank');
  aElement.click();
  URL.revokeObjectURL(href);
};

const extractFromContentDisposition = (contentDisposition: string, field: string) => {
  const items = contentDisposition.split('; ');
  let value = null;

  items.forEach((item: string) => {
    if (item.includes(field)) {
      const [, v] = item.split(field);
      value = v;
    }
  });

  return value;
};

const getFileFromStorage = (url: string, fileName: string) => {
  fetch(`${window.__RUNTIME_CONFIG__.DOCUMENT_ROOT}${url}`, {
    method: 'get',
    mode: 'no-cors',
    referrerPolicy: 'no-referrer',
  })
    .then((res) => res.blob())
    .then((res) => {
      downloadFile(res, fileName);
    });
};

const getFileFromApi = async (url: string) => {
  try {
    const response = await http.get(url);
    const blob = new Blob([response.data], { type: response.headers['content-type'] });
    const fileName =
      extractFromContentDisposition(response.headers['content-disposition'], 'filename=') ?? 'Export.csv';
    downloadFile(blob, fileName);
    return true;
  } catch (error) {
    return false;
  }
};

export { getFileFromStorage, getFileFromApi };
