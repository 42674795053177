import React, { useContext, createContext, ReactNode, useState, useMemo } from 'react';
import { Result, ResultContextType } from '../types';

export const ResultContext = createContext<ResultContextType>({
  result: {
    echteLeeftijd: 0,
    inputLeeftijd: 0,
    surveyId: 0,
    responseCode: '',
    topImprovements: [],
    topCompliments: [],
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setResult: (result: Result) => {},
});

export const useResultContext = () => useContext(ResultContext);

type PropType = {
  children: ReactNode[] | ReactNode;
};

export const ResultContextProvider = (props: PropType) => {
  const [result, updateResult] = useState<Result>(
    JSON.parse(localStorage.getItem('gezondleven-result') || 'null') || {
      echteLeeftijd: 0,
      inputLeeftijd: 0,
      surveyId: 0,
      topImprovements: [],
      topCompliments: [],
    },
  );

  const setResult = (r: Result) => {
    localStorage.setItem('gezondleven-result', JSON.stringify(r));
    updateResult(r);
  };

  const { children } = props;
  const context = useMemo(
    () => ({
      result,
      setResult,
    }),
    [result],
  );

  return <ResultContext.Provider value={context}>{children}</ResultContext.Provider>;
};
