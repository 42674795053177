import useBem from '@indicia/use-bem';
import { useTranslation } from 'react-i18next';

import './SurveyProgress.scss';

type SurveyProgressType = {
  currentQuestion: number;
  totalQuestions: number;
};

const SurveyProgress = ({ currentQuestion, totalQuestions }: SurveyProgressType) => {
  const { bemClassName, bemClassNames } = useBem('survey-progress');
  const { t } = useTranslation();

  const progress = currentQuestion + 1;
  const percentage = Math.round((progress / totalQuestions) * 100);

  return (
    <div className={bemClassName()}>
      <div className={bemClassName('bar')}>
        <div
          className={bemClassName('progress')}
          style={{ width: `${percentage}%` }}
        />
      </div>
      <div className={bemClassName('labels')}>
        <div className={bemClassNames([bemClassName('label'), bemClassName('label', 'current')])}>
          {t('survey.current', { progress })}
        </div>
        <div
          className={bemClassNames([
            bemClassName('label'),
            bemClassName('label', 'percentage'),
            bemClassName('label', 'completed', percentage === 100),
          ])}
        >
          {t('survey.percentage', { percentage })}
        </div>
      </div>
    </div>
  );
};

export default SurveyProgress;
