import React from 'react';
import useBem from '@indicia/use-bem';

import './QuestionTitle.scss';

type PropType = {
  title: string;
  questionId: string;
};

const QuestionTitle = ({ title, questionId }: PropType) => {
  const { bemClassName } = useBem('question-title');

  return (
    <div className={bemClassName()}>
      <h1
        id={questionId}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: title }}
      />
    </div>
  );
};

export default QuestionTitle;
