import React, { useState } from 'react';

import '../SurveyQuestion.scss';
import { SurveySliderQuestionType } from '../SurveyQuestionTypes';
import SurveyAnswer from '../../../../shared/models/survey/SurveyAnswer';
import QuestionSlider from '../../../../shared/components/slider/QuestionSlider';

type PropType = SurveySliderQuestionType & {
  questionKey: string;
  onAnswerSelect: (answer: SurveyAnswer) => void;
  previousAnswer: number | undefined;
  bem: (element: string) => string;
};

const SurveySliderQuestion = ({ bem, maxValue, minValue, questionKey, onAnswerSelect, previousAnswer }: PropType) => {
  const [selectedAnswer, setSelectedAnswer] = useState<number | undefined>(previousAnswer);

  const handleOnChange = (value: number) => {
    setSelectedAnswer(value);

    onAnswerSelect({
      value,
      questionId: questionKey,
    });
  };

  return (
    <div className={bem('slider')}>
      <QuestionSlider
        previousAnswer={selectedAnswer}
        minValue={minValue}
        maxValue={maxValue}
        onChange={(value: number) => {
          handleOnChange(value);
        }}
        smallStepValue={1}
      />
    </div>
  );
};

export default SurveySliderQuestion;
