import React, { FC } from 'react';
import ThemeLink from '../theme-link/theme-link';
import { SolutionType } from '../../types';

type PropType = {
  className: string;
} & SolutionType;

const ThemeEmployerHelpItem: FC<PropType> = ({ className, title, description, externalLink }) => (
  <div className={className}>
    <h4>{title}</h4>
    {/* eslint-disable-next-line react/no-danger */}
    <p dangerouslySetInnerHTML={{ __html: description }} />
    {externalLink && <ThemeLink {...externalLink} />}
  </div>
);

export default ThemeEmployerHelpItem;
