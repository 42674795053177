import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

enum StatusCode {
  Unauthorized = 401,
  Forbidden = 403,
  TooManyRequests = 429,
  InternalServerError = 500,
  BadRequest = 502,
}

const headers: Readonly<Record<string, string | boolean>> = {
  Accept: 'application/json',
  'Content-Type': 'application/json; charset=utf-8',
};

class Http {
  private instance: AxiosInstance | null = null;

  tokenGenerator: any;

  private get http(): AxiosInstance {
    return this.instance != null ? this.instance : this.initHttp();
  }

  initHttp() {
    const http = axios.create({
      baseURL: window.__RUNTIME_CONFIG__.ODATA_URL,
      headers,
      withCredentials: false,
    });

    this.instance = http;
    return http;
  }

  request<T = any, R = AxiosResponse<T>>(config: AxiosRequestConfig): Promise<R> {
    return this.http.request(config);
  }

  get<T = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
    return this.http.get<T, R>(url, config);
  }

  async post<T = any, R = AxiosResponse<T>>(url: string, data?: T, config?: AxiosRequestConfig): Promise<R> {
    return this.http.post<T, R>(url, data, config);
  }

  async patch<T = any, R = AxiosResponse<T>>(url: string, data?: T, config?: AxiosRequestConfig): Promise<R> {
    return this.http.patch<T, R>(url, data, config);
  }

  put<T = any, R = AxiosResponse<T>>(url: string, data?: T, config?: AxiosRequestConfig): Promise<R> {
    return this.http.put<T, R>(url, data, config);
  }

  delete<T = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
    return this.http.delete<T, R>(url, config);
  }

  // Handle global app errors
  // We can handle generic app errors depending on the status code
  // eslint-disable-next-line class-methods-use-this
  private handleError(error: AxiosError) {
    const { status } = error;

    switch (status) {
      case StatusCode.InternalServerError: {
        // eslint-disable-next-line no-console
        console.log('Internal Server Error');
        break;
      }
      case StatusCode.Unauthorized: {
        // eslint-disable-next-line no-console
        console.log('Unauthorized');
        break;
      }
      case StatusCode.TooManyRequests: {
        // eslint-disable-next-line no-console
        console.log('Too Many Requests');
        break;
      }
      case StatusCode.Forbidden:
      default: {
        // eslint-disable-next-line no-console
        console.log('Forbidden');
        break;
      }
    }

    return Promise.reject(error);
  }
}

const http = new Http();
export default http;
