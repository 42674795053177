import { ReactNode, FC } from 'react';
import useBem from '@indicia/use-bem';

import './background-container.scss';

type PropsType = {
  type?: 'beige' | 'white' | 'theme-header' | 'tips' | 'dashboard-header';
  children: ReactNode | ReactNode[];
};

const BackgroundContainer: FC<PropsType> = ({ type = 'white', children = undefined }) => {
  const { bemClassName, bemClassNames } = useBem('background-container');

  return <div className={bemClassNames([bemClassName(), bemClassName(null, type)])}>{children}</div>;
};

export default BackgroundContainer;
