import http from '../../../shared/utils/HttpClient';
import Survey from '../../../shared/models/survey/Survey';
import SurveyAnswer from '../../../shared/models/survey/SurveyAnswer';
import { Result } from '../../dashboard/types';
import SurveyRequestResponse from '../../../shared/models/survey/SurveyRequestResponse';

const getSurvey = async (surveyId: number, activationCode: string) => {
  const url = `Surveys?surveyId=${surveyId}&activationCode=${activationCode}`;
  const { data } = await http.get(url);
  return SurveyRequestResponse.parse(data);
};

const getSurveyWithResponse = async (surveyId: number, activationCode: string, responseCode: string) => {
  let url = `Surveys?surveyId=${surveyId}&activationCode=${activationCode}`;
  if (responseCode) {
    url = `${url}&responseCode=${responseCode}`;
  }

  const { data } = await http.get(url);
  return {
    survey: Survey.fromJson(data.specification),
    sessionKey: data.sessionKey,
    answers: SurveyAnswer.fromJsonList(data.answers),
  };
};

const postAnswers = async (
  surveyId: number,
  answers: SurveyAnswer[],
  activationCode: string,
  saveData: boolean,
  email?: string,
  sessionKey?: string,
): Promise<Result> => {
  const dataObj: {
    answers: SurveyAnswer[];
    activationCode: string;
    saveData: boolean;
    emailAddress?: string;
    sessionKey?: string;
  } = {
    answers,
    activationCode,
    saveData,
  };

  if (sessionKey) dataObj.sessionKey = sessionKey;
  if (email) dataObj.emailAddress = email;

  const { data } = await http.post(`Responses?surveyId=${surveyId}`, dataObj);
  // @ts-ignore
  return { data };
};

export { getSurvey, getSurveyWithResponse, postAnswers };
