import SurveyQuestionType from './SurveyQuestionType';
import SurveyQuestion from './SurveyQuestion';

class SurveyQuestionText extends SurveyQuestion {
  value: string;

  constructor(
    questionId: string,
    questionType: SurveyQuestionType,
    title: string,
    description: string,
    group: string,
    variability: string,
    identifier: string,
    value: string,
    info?: string,
    suffix?: string,
  ) {
    super(questionId, questionType, title, description, group, variability, identifier, info, suffix);

    this.value = value;
  }

  public static parse(object: any) {
    return new SurveyQuestionText(
      object.QuestionId,
      object.QuestionType,
      object.Title,
      object.Description,
      object.Group,
      object.Variability,
      object.Identifier,
      object.Value,
      object.Info ?? undefined,
      object.Suffix ?? undefined,
    );
  }
}

export default SurveyQuestionText;
