import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import LoaderContainer from '../../../shared/components/loader/LoaderContainer';
import { useSurveyContext } from '../../survey/store/SurveyContext';
import { getSurveyWithResponse, postAnswers } from '../../survey/data/SurveyService';
import { useResultContext } from '../store/ResultContext';
import { Result } from '../types';

const ResultHandler = () => {
  const { t } = useTranslation();
  const { activationCode, resultCode } = useParams();
  const { setQuestionAnswers, setBackupQuestionAnswers, setSurvey, setActivationCode } = useSurveyContext();
  const { setResult } = useResultContext();
  const navigator = useNavigate();
  const surveyId = 1;

  if (!activationCode || !resultCode) {
    return (
      <div>
        <h1>Invalid result url</h1>
      </div>
    );
  }

  useEffect(() => {
    const fetchResult = async () => {
      const { answers, survey } = await getSurveyWithResponse(surveyId, activationCode, resultCode);
      setQuestionAnswers(answers);
      setBackupQuestionAnswers(answers);
      setSurvey(survey);
      setActivationCode(activationCode);

      const result = await postAnswers(surveyId, answers, activationCode, false);
      // @ts-ignore
      const { data } = result;
      setResult(data as Result);

      navigator('/dashboard');
    };

    if (activationCode && resultCode) {
      fetchResult();
    }

    return () => {};
  }, [activationCode, resultCode]);

  return <LoaderContainer text={t('survey.loading-results')} />;
};

export default ResultHandler;
