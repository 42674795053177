import { FC } from 'react';
import useBem from '@indicia/use-bem';

import BackgroundContainer from '../../../../shared/components/background-container/background-container';
import ThemeZkHelpItem from './theme-zk-help-item';
import { SolutionsContainerType } from '../../types';

import './theme-zk-help.scss';

const ThemeZkHelp: FC<SolutionsContainerType> = ({ title, footerText, solutions }) => {
  const { bemClassName, bemClassNames } = useBem('theme-zk-help');

  return (
    <BackgroundContainer type="beige">
      <div className={bemClassNames([bemClassName(''), 'container'])}>
        <div className={bemClassName('zk-help-header')}>
          <div className={bemClassName('zk-help-header-logo')}>
            <img
              src="/images/logos/zk.svg"
              alt="achmea"
            />
          </div>
          <div className={bemClassName('zk-help-header-content')}>
            <h3>{title}</h3>
          </div>
        </div>
        <div className={bemClassName('zk-help-item-list')}>
          {solutions.map((solution) => {
            const { title: solutionTitle } = solution;

            return (
              <ThemeZkHelpItem
                key={solutionTitle}
                {...solution}
              />
            );
          })}
        </div>
        <div className={bemClassName('zk-help-footer')}>{footerText}</div>
      </div>
    </BackgroundContainer>
  );
};

export default ThemeZkHelp;
