import { createBrowserRouter } from 'react-router-dom';

import App from './App';
import { StartSurvey, SurveyPage } from './features/survey/pages';
import ThemePage from './features/result/theme-page';
import DashboardPage from './features/dashboard/DashboardPage';
import { AboutTest, AboutUs, Faq } from './features/content';
import ResultHandler from './features/dashboard/components/ResultHandler';
import Export from './features/export/Export';
import NotFound from './features/error/pages/NotFound';
import ErrorPage from './features/error/pages/ErrorPage';

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      {
        path: '/',
        element: <StartSurvey />,
      },
      {
        path: '/:activationCode',
        element: <StartSurvey />,
      },
      {
        path: '/survey',
        element: <SurveyPage />,
      },
      {
        path: '/dashboard',
        element: <DashboardPage />,
      },
      {
        path: '/about-us',
        element: <AboutUs />,
      },
      {
        path: '/gezondleventest',
        element: <AboutTest />,
      },
      {
        path: '/thema/:theme',
        element: <ThemePage />,
      },
      {
        path: '/faq',
        element: <Faq />,
      },
      {
        path: '/result/:activationCode/:resultCode',
        element: <ResultHandler />,
      },
      {
        path: '/export',
        element: <Export />,
      },
      {
        path: '*',
        element: <NotFound />,
      },
    ],
    errorElement: <ErrorPage />,
  },
]);

export default router;
