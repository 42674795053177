import useBem from '@indicia/use-bem';

import HabitType from './HabitType';
import HabitSlider from './HabitSlider';
import HabitRadio from './HabitRadio';
import HabitNumericInput from './HabitNumericInput';

import { HabitNumericInputDetails, HabitSliderDetails, HabitRadioDetails } from './types';
import SurveyAnswer from '../../../../shared/models/survey/SurveyAnswer';

import './Habit.scss';

type HabitPropType = {
  onChange: (surveyAnswer: SurveyAnswer) => void;
  title: string;
  type: HabitType;
  questionId: string;
  valueDetails: HabitSliderDetails | HabitRadioDetails | HabitNumericInputDetails;
};

const Habit = ({ onChange, title, type, questionId, valueDetails }: HabitPropType) => {
  const { bemClassName } = useBem('habit');
  const { currentValue } = valueDetails;

  const handleOnChangeString = (v: string) => {
    onChange({ value: [v], questionId });
  };
  const handleOnChangeNumeric = (v: number) => {
    onChange({ value: v, questionId });
  };

  return (
    <div className={bemClassName()}>
      <div className={bemClassName('title')}>
        {/* eslint-disable-next-line react/no-danger */}
        <span dangerouslySetInnerHTML={{ __html: title }} />
        {type === HabitType.SLIDER && <span className={bemClassName('title__score')}>{currentValue}</span>}
      </div>
      <div className={bemClassName('content')}>
        {type === HabitType.SLIDER && (
          <HabitSlider
            {...(valueDetails as HabitSliderDetails)}
            onChange={handleOnChangeNumeric}
            questionKey={questionId}
          />
        )}
        {type === HabitType.RADIO && (
          <HabitRadio
            {...(valueDetails as HabitRadioDetails)}
            onChange={handleOnChangeString}
            questionKey={questionId}
          />
        )}
        {type === HabitType.INPUT && (
          <HabitNumericInput
            {...(valueDetails as HabitNumericInputDetails)}
            onChange={handleOnChangeNumeric}
            questionKey={questionId}
          />
        )}
      </div>
    </div>
  );
};

export default Habit;
