import React, { useContext, createContext, ReactNode, useState, useMemo } from 'react';
import SurveyAnswer from '../../../shared/models/survey/SurveyAnswer';
import Survey from '../../../shared/models/survey/Survey';
import { SurveyContextType } from '../types';
import getRoute from '../../../shared/utils/get-route';

export const SurveyContext = createContext<SurveyContextType>({
  email: '',
  questionAnswers: [] as SurveyAnswer[],
  questionNumber: 0,
  survey: undefined,
  activationCode: '',
  sessionKey: '',
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setQuestionAnswer: (questionAnswer: SurveyAnswer) => {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setQuestionAnswers: (questionAnswers: SurveyAnswer[]) => {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setBackupQuestionAnswers: (questionAnswers: SurveyAnswer[]) => {},
  getBackupQuestionAnswers: () => [],
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setQuestionNumber: (questionNumber: number) => {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setEmailAddress: (email: string) => {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setSurvey: (survey: Survey) => {},
  removeAnswers: () => {},
  setAllAnswers: () => {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setActivationCode: (code: string) => {},
  resetAnswers: () => {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setSessionKey: (key: string) => {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getAdvice: (identifier: string, isPositive: boolean) => '',
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getThemeName: (identifier: string) => '',
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getLink: (identifier: string) => '',
});

export const useSurveyContext = () => useContext(SurveyContext);

type PropType = {
  children: ReactNode[] | ReactNode;
};

// TODO REMOVE THIS FUNCTIONALITY AFTER TESTING
const surveyAnswersFilled = [
  { value: 33, questionId: 'f21b1948-d7b9-4062-ad55-7b3efc750036' },
  { value: ['6a224221-49ee-4011-904e-744a0a402aea'], questionId: '5e378874-7f7e-47ef-9549-12528c4fb92a' },
  { value: 1.89, questionId: '262ecdc1-1ef7-40fb-8f4f-c4d6da1ea886' },
  { value: 110, questionId: '3011fa8b-e489-4bb7-8f1c-2ba0e73f4116' },
  { value: ['4d1d7a89-424f-4a1f-a5af-30831f0731da'], questionId: '894e54b4-991f-42e6-bce9-f6e53926b09f' },
  { value: ['c5b4e730-7e3f-470e-9af0-6d8660db5ff6'], questionId: '72dea9a6-ce42-4115-87aa-904461896767' },
  { value: ['1714bb10-1e83-4c72-b8a4-6a371fe7fb7d'], questionId: '09aa7648-952a-4bfa-b445-6cdea6da6061' },
  { value: ['f32007a2-1b7e-449d-a4b0-bdfcdc607b28'], questionId: 'e48ed0bc-59a2-48fb-a993-c400927e8103' },
  { value: ['a06a8269-7fb0-46b1-a89a-4dd7bf21f4ef'], questionId: 'a1aab366-1a89-4fc0-a901-7a0e5cc8cda3' },
  { value: ['2c31e78d-6555-4684-be75-a77812c63c1c'], questionId: '6c3d68b3-b4c5-4c88-a8ff-ede0615f537d' },
  { value: ['a3d0f2f0-ff8c-4096-ab9b-17fa093f45d0'], questionId: '189a469b-a6b8-47e9-97ff-9ab46a66c0ee' },
  { value: ['5c2ddd6b-8aed-489d-89c6-ef851cbe99bf'], questionId: 'c7da485e-03f6-4ab6-90aa-207b8744c583' },
  { value: ['6ec543ae-0d4e-4325-bcb4-1fb918dd5b52'], questionId: '74862abb-2f34-4b2a-be0c-3e795421db8e' },
  { value: ['48d03fce-5156-4e46-bff7-4b3bcfd7c607'], questionId: '52b41225-4719-49ff-8047-244ef50168c6' },
  { value: ['ea2e23e0-2dde-421a-a1d1-3c20ccdb10f6'], questionId: '0df8a010-1a82-453e-a43b-95ec65264405' },
  { value: ['b7c5a01c-2d3b-42ce-a98e-6de376d0f862'], questionId: 'e8f3e903-7e46-4b0a-bde3-0b4e764e3bdc' },
  { value: ['7c6cc246-2b6c-481a-9b5f-7468024227b2'], questionId: '891f8b9b-ea62-4de8-912d-4b2c0f89ccd2' },
  { value: ['4f7d332f-8efb-4c86-ab35-bca2bbca60d1'], questionId: '090418c2-d213-41cf-b02d-96fefd24b710' },
  { value: 7, questionId: '5c13ec14-d2f3-4424-8d8c-1f48fb777a27' },
  { value: 0, questionId: 'dbece3b9-5a3f-41dc-942d-49d7a5a83b08' },
  { value: 0, questionId: 'd39d6a57-d387-4c50-a3f6-3296914fcee2' },
  { value: 4, questionId: 'b259e8f2-e0c4-4bbc-a9dd-0d278b5b9425' },
  { value: ['b7a3e7a2-a04b-4f30-b74a-463e7808325a'], questionId: 'dfb55139-2c5c-49be-b492-24f1cece6ede' },
  { value: ['939eeec9-098e-42c5-9592-781cfa0a324d'], questionId: '22aeb1e4-ef31-4ca4-a8c3-399f78d2e81b' },
  { value: ['4ce542ba-ef71-4c30-ae38-c3849d8fe2e2'], questionId: '04d21e1d-85db-4734-a338-39db2830453c' },
  { value: ['d28b687b-b1cc-4c80-8902-eb2beb5cd324'], questionId: '8c25e5a9-72da-46c9-a4ff-dbc20a42a46e' },
  { value: ['8e1f6ef1-22c2-4ccb-ae1a-bd42e896f503'], questionId: 'f33a9218-e018-4f5c-a287-bdcb0ff46e59' },
  { value: ['66a3476d-2e52-4115-84b9-4357e63341e7'], questionId: 'c28e7fcb-199a-4046-af9c-91893b814acb' },
  { value: ['1eb11aa0-a11f-4c16-abaf-5d9cc151e95f'], questionId: '5ead0297-c6bd-456b-b212-e782774ec730' },
  { value: ['e22bcbee-8a91-4eb7-8b01-8bdb98b444d0'], questionId: '1cefaa0c-48e1-41ae-b2c4-66f1baef8d53' },
  { value: ['a2e6a2d9-4245-43fa-b007-94658cd68bbe'], questionId: 'a4696ef6-3d40-45ed-9929-b1c171874336' },
  { value: ['36af1f43-6ef2-4929-a811-c352adf8ce6c'], questionId: 'f0a476f5-bb64-4eda-af50-0b4f7de9789b' },
  { value: ['e1861d97-0954-4c9a-96e9-b695193b67bb'], questionId: 'c63e7f7c-3113-4049-9d8d-a2f4855f874a' },
  { value: ['b4a7a5e1-eaa8-49da-a28f-d08fdafcf649'], questionId: 'b8477a06-1e3c-4169-9615-b48c733cfd31' },
  { value: ['69c17f6c-55c2-4dcd-b3f4-10f8af311ba4'], questionId: 'b8a73cc4-4260-42b2-8305-921ef981112b' },
  { value: ['1bb2b2bb-de23-4d82-b02f-254a52c63326'], questionId: 'dc0331fc-f39d-4a8d-a758-56bb9fb29e49' },
  { value: ['aa54a972-a6d1-431e-9412-dfc9ea817315'], questionId: '564b3b5d-ceea-404e-ad56-a2c92261e3d1' },
  { value: ['a0dbe548-2d34-4d79-b1a1-9104896dcd32'], questionId: '71d540b5-6927-4ea3-bb8d-5a75c3aea379' },
  { value: ['d10e7f3c-8329-40d6-9dfd-d3193c002140'], questionId: 'd0363f02-f38f-4269-8a10-1921818e5784' },
];

export const SurveyContextProvider = (props: PropType) => {
  const storedAnswers = JSON.parse(localStorage.getItem('gezondleven-answers') || '[]');
  const [email, updateEmail] = useState<string>('');
  const [survey, updateSurvey] = useState<Survey | undefined>(undefined);
  const [questionAnswers, updateQuestionAnswers] = useState<SurveyAnswer[]>(storedAnswers || []);
  const [questionNumber, updateQuestionNumber] = useState<number>(storedAnswers.length);
  const [activationCode, updateActivationCode] = useState<string>(
    localStorage.getItem('gezondleven-activation-code') || '',
  );
  const [sessionKey, updateSessionKey] = useState<string>('');

  const getThemeName = (identifier: string): string => {
    const theme = survey?.themes?.find((surveyTheme) => surveyTheme.identifier === identifier);
    return theme?.name ?? '';
  };

  const getAdvice = (identifier: string, isPositive: boolean): string => {
    const adviceMapping = survey?.adviceMappings?.find((am) => am.identifier === identifier);
    if (!adviceMapping) return '';
    return isPositive ? adviceMapping.positiveName : adviceMapping.negativeName;
  };

  const getLink = (identifier: string): string => getRoute(identifier);

  const setQuestionAnswer = (questionAnswer: SurveyAnswer | undefined) => {
    let newAnswers: SurveyAnswer[];
    if (questionAnswer) {
      const objIndex = questionAnswers.findIndex((obj) => obj.questionId === questionAnswer.questionId);

      if (objIndex !== -1) {
        questionAnswers[objIndex] = questionAnswer;
        newAnswers = [...questionAnswers];
      } else {
        newAnswers = [...questionAnswers, questionAnswer];
      }
    } else {
      newAnswers = [];
    }

    localStorage.setItem('gezondleven-answers', JSON.stringify(newAnswers));
    updateQuestionAnswers(newAnswers);
  };

  const setQuestionAnswers = (answers: SurveyAnswer[]) => {
    updateQuestionAnswers([...answers]);
  };

  const resetAnswers = () => {
    const answers = JSON.parse(localStorage.getItem('gezondleven-answers-backup') || '');
    updateQuestionAnswers([...answers]);
  };

  const getBackupQuestionAnswers = () => JSON.parse(localStorage.getItem('gezondleven-answers-backup') || '');

  const setBackupQuestionAnswers = (answers: SurveyAnswer[]) =>
    localStorage.setItem('gezondleven-answers-backup', JSON.stringify(answers));

  const setAllAnswers = () => {
    updateQuestionAnswers(surveyAnswersFilled);
    updateQuestionNumber(38);
  };

  const setEmailAddress = (address: string) => {
    updateEmail(address);
  };

  const setQuestionNumber = (qn: number) => {
    updateQuestionNumber(qn);
  };

  const setSurvey = (s: Survey) => {
    updateSurvey(s);
  };

  const removeAnswers = () => {
    updateQuestionAnswers([]);
    updateQuestionNumber(0);
  };

  const setActivationCode = (code: string) => {
    localStorage.setItem('gezondleven-activation-code', code);
    updateActivationCode(code);
  };

  const setSessionKey = (key: string) => {
    updateSessionKey(key);
  };

  const { children } = props;
  const context = useMemo(
    () => ({
      email,
      questionAnswers,
      questionNumber,
      survey,
      activationCode,
      sessionKey,
      setQuestionAnswer,
      setQuestionAnswers,
      setBackupQuestionAnswers,
      getBackupQuestionAnswers,
      setQuestionNumber,
      setEmailAddress,
      setSurvey,
      removeAnswers,
      setAllAnswers,
      setActivationCode,
      resetAnswers,
      setSessionKey,
      getAdvice,
      getThemeName,
      getLink,
    }),
    [questionAnswers, questionNumber, email, survey],
  );

  return <SurveyContext.Provider value={context}>{children}</SurveyContext.Provider>;
};
