import { NumericTextBox, NumericTextBoxChangeEvent } from '@progress/kendo-react-inputs';
import { HabitNumericInputDetails } from './types';

type HabitSliderDetailsPropType = {
  onChange: (value: number) => void;
} & HabitNumericInputDetails;

const HabitNumericInput = ({ currentValue, minValue, maxValue, onChange, questionKey }: HabitSliderDetailsPropType) => (
  <div className="habit__input">
    <NumericTextBox
      id={questionKey}
      value={currentValue}
      min={minValue}
      max={maxValue}
      defaultValue={currentValue}
      onChange={(event: NumericTextBoxChangeEvent) => {
        const { value: v } = event;
        const newValue = Math.round(v!);
        onChange(newValue);
      }}
    />
  </div>
);

export default HabitNumericInput;
