import { FC } from 'react';
import useBem from '@indicia/use-bem';

import { SolutionsContainerType } from '../../types';
import ThemeEmployerHelpItem from './theme-employer-help-item';
import BackgroundContainer from '../../../../shared/components/background-container/background-container';

import './theme-employer-help.scss';

const ThemeEmployerHelp: FC<SolutionsContainerType> = ({ title, description, footerText, solutions, image }) => {
  const { bemClassName, bemClassNames } = useBem('theme-employer-help');

  return (
    <BackgroundContainer type="white">
      <div className={bemClassNames([bemClassName(), 'container'])}>
        <div className={bemClassName('header')}>
          <div className={bemClassName('header-logo')}>
            <img
              src={image}
              alt=""
            />
          </div>
          <div className={bemClassName('header-content')}>
            <h3>{title}</h3>
            <p>{description}</p>
            {footerText && <p className="small-script">{footerText}</p>}
          </div>
        </div>
        <div className={bemClassName('item-list')}>
          {solutions.map((solution) => {
            const { title: solutionTitle } = solution;

            return (
              <ThemeEmployerHelpItem
                key={solutionTitle}
                className={bemClassName('item')}
                {...solution}
              />
            );
          })}
        </div>
      </div>
    </BackgroundContainer>
  );
};

export default ThemeEmployerHelp;
