import { FC } from 'react';
import { useParams } from 'react-router-dom';
import useBem from '@indicia/use-bem';

import { ThemeHeaderType } from '../../types';
import IconRender from '../../../../shared/components/icon/IconRender';
import getIcon from '../../../../shared/utils/get-icon';
import BackgroundContainer from '../../../../shared/components/background-container/background-container';

import './theme-header.scss';

const ThemeHeader: FC<ThemeHeaderType> = ({ title, description, image }) => {
  const { bemClassName, bemClassNames } = useBem('theme-header');
  const { theme } = useParams();

  return (
    <BackgroundContainer type="theme-header">
      <div className={bemClassNames([bemClassName(), 'container'])}>
        <div className={bemClassName('content')}>
          <div className={bemClassName('content-logo')}>
            <div className={bemClassName('content-logo-background')}>
              <IconRender
                icon={getIcon(theme!)}
                color="var(--zk-dark-blue)"
                width="36px"
                height="36px"
              />
            </div>
          </div>
          <div className={bemClassName('content-text')}>
            <h1 className={bemClassName('content-title')}>
              <div className={bemClassName('content-logo')}>
                <div className={bemClassName('content-logo-background')}>
                  <IconRender
                    icon={getIcon(theme!)}
                    color="var(--zk-dark-blue)"
                    width="20px"
                    height="20px"
                  />
                </div>
              </div>
              {title}
            </h1>
            <p className={bemClassName('content-text')}>{description}</p>
          </div>
        </div>
        <div className={bemClassName('image')}>
          <img
            src={image}
            alt=""
            width={412}
          />
        </div>
      </div>
    </BackgroundContainer>
  );
};

export default ThemeHeader;
