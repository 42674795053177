import useBem from '@indicia/use-bem';
import { useTranslation } from 'react-i18next';

import { useSurveyContext } from '../../store/SurveyContext';
import Icon from '../../../../shared/components/button/enums/Icon';
import Button from '../../../../shared/components/button/Button';
import ButtonTheme from '../../../../shared/components/button/enums/ButtonTheme';
import IconPosition from '../../../../shared/components/button/enums/IconPosition';

import './SurveyNavigation.scss';

type PropType = {
  labelNext: string;
  onHandleNext: () => void;
  onHandlePrevious: () => void;
  onHandleFinish: () => void;
  labelPrev: string;
  nextDisabled: boolean;
  firstQuestion: boolean;
  lastQuestion: boolean;
};

const SurveyNavigation = (props: PropType) => {
  const { DEV_MODE } = window.__RUNTIME_CONFIG__;

  const { bemClassName, bemClassNames } = useBem('survey-navigation');
  const {
    labelPrev,
    labelNext,
    onHandleNext,
    onHandlePrevious,
    onHandleFinish,
    nextDisabled,
    firstQuestion,
    lastQuestion,
  } = props;
  const { t } = useTranslation();
  const { setAllAnswers } = useSurveyContext();

  let label = labelNext;

  if (lastQuestion) {
    label = t('survey.submit');
  }

  return (
    <div className="content-container">
      <div className={bemClassName()}>
        <div className={bemClassNames([bemClassName('content'), bemClassName('content', 'single', firstQuestion)])}>
          {!firstQuestion && (
            <Button
              onClick={onHandlePrevious}
              icon={Icon.arrowLeft}
              iconPosition={IconPosition.LEFT}
              theme={ButtonTheme.INVERTED}
              label={labelPrev}
            />
          )}
          {DEV_MODE === 'true' && (
            <Button
              onClick={setAllAnswers}
              icon={Icon.chevronRight}
              label="Set all answers"
            />
          )}
          <Button
            onClick={lastQuestion ? onHandleFinish : onHandleNext}
            icon={Icon.chevronRight}
            label={label}
            disabled={nextDisabled}
          />
        </div>
      </div>
    </div>
  );
};

export default SurveyNavigation;
