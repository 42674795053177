const getRoute = (category: string) => {
  let slug = '';

  switch (category) {
    case 'Cholesterol':
    case 'Triglicerides':
    case 'Hdl':
      slug = 'Cholesterol';
      break;
    case 'Bloeddruk':
    case 'Vitals':
      slug = 'Bloeddruk';
      break;
    case 'Nutrition':
    case 'Diet':
    case 'Voeding':
      slug = 'Voeding';
      break;
    case 'Bmi':
    case 'Weight':
    case 'Gewicht':
      slug = 'Gewicht';
      break;
    case 'Smoking':
    case 'Roken':
      slug = 'Roken';
      break;
    case 'Coffee':
    case 'Koffie':
      slug = 'Koffie';
      break;
    case 'Exersize':
    case 'HyperTension':
    case 'Bewegen':
      slug = 'Bewegen';
      break;
    case 'Slapen':
    case 'Sleep':
      slug = 'Slapen';
      break;
    case 'Stress':
      slug = 'Stress';
      break;
    case 'Alcohol':
      slug = 'Alcohol';
      break;
    default:
      throw new Error(`Route not found for category: ${category}`);
  }

  return `/thema/${slug}`;
};

export default getRoute;
