import { Outlet } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { SurveyContextProvider } from './features/survey/store/SurveyContext';
import { ResultContextProvider } from './features/dashboard/store/ResultContext';
import HeaderBar from './shared/components/header/HeaderBar';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: true,
      refetchOnMount: true,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 5 * 60 * 1000,
    },
  },
});

const App = () => (
  <QueryClientProvider client={queryClient}>
    <ResultContextProvider>
      <SurveyContextProvider>
        <HeaderBar />
        <Outlet />
      </SurveyContextProvider>
    </ResultContextProvider>
  </QueryClientProvider>
);

export default App;
