import { useState } from 'react';
import useBem from '@indicia/use-bem';
import { useTranslation } from 'react-i18next';

import Button from '../../shared/components/button/Button';
import ButtonTheme from '../../shared/components/button/enums/ButtonTheme';
import { getFileFromApi } from '../../shared/utils/DownloadFile';

import './Export.scss';

const Export = () => {
  const { bemClassName } = useBem('export');
  const { t } = useTranslation();
  const [companyCode, setCompanyCode] = useState('');
  const [password, setPassword] = useState('');
  const [showError, setShowError] = useState(false);

  const onClick = async () => {
    if (password && companyCode) {
      const url = `Csv?orgUnitCode=${companyCode}&password=${password}`;
      const result = await getFileFromApi(url);
      setShowError(!result);
    }
  };

  return (
    <div className={bemClassName()}>
      <div className={bemClassName('container')}>
        <div className={bemClassName('content')}>
          <h1>{t('export.title')}</h1>
          <p>{t('export.content')}</p>
          {showError && <p className="error">{t('export.error')}</p>}
        </div>
        <div className={bemClassName('form')}>
          <div className={bemClassName('form-item')}>
            <label htmlFor="CompanyCode">{t('export.activation-code')}</label>
            <input
              id="CompanyCode"
              name="CompanyCode"
              onChange={(e) => {
                const {
                  target: { value },
                } = e;
                setCompanyCode(value);
              }}
            />
          </div>
          <div className={bemClassName('form-item')}>
            <label htmlFor="Password">{t('export.password')}</label>
            <input
              id="Password"
              name="Password"
              type="password"
              onChange={(e) => {
                const {
                  target: { value },
                } = e;
                setPassword(value);
              }}
            />
          </div>
          <div className={bemClassName('form-item')}>
            <Button
              onClick={() => onClick()}
              theme={ButtonTheme.NORMAL}
              label={t('generic.download')!}
              disabled={password === '' || companyCode === ''}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Export;
