class SurveyChoiceOption {
  id: string;

  description: string;

  value: string | null;

  constructor(id: string, description: string, value: string) {
    this.id = id;
    this.description = description;
    this.value = value;
  }

  public static parse(object: any) {
    return new SurveyChoiceOption(object.Id, object.Description, object.Value);
  }
}

export default SurveyChoiceOption;
