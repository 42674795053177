import useBem from '@indicia/use-bem';
import { useTranslation } from 'react-i18next';
import { NavLink, useNavigate, useParams } from 'react-router-dom';

import LoaderContainer from '../../shared/components/loader/LoaderContainer';
import IconRender from '../../shared/components/icon/IconRender';
import ThemeHeader from './components/theme-header/theme-header';
import ThemeTips from './components/theme-tips/theme-tips';
import useTheme from './hooks/use-theme';
import ThemeEmployerHelp from './components/theme-employer-help/theme-employer-help';
import ThemeZkHelp from './components/theme-zk-help/theme-zk-help';
import Icon from '../../shared/components/button/enums/Icon';
import BackgroundContainer from '../../shared/components/background-container/background-container';

import './theme-page.scss';

const ThemePage = () => {
  const { t } = useTranslation();
  const { theme } = useParams();
  const navigate = useNavigate();
  const key = localStorage.getItem('gezondleven-activation-code');

  if (!key || !theme) {
    navigate('/');
  }

  const { bemClassName, bemClassNames } = useBem('theme-page');
  const { data, isLoading } = useTheme(key ?? '', theme!);

  if (isLoading) {
    return <LoaderContainer text={t('theme.loading')} />;
  }

  const { header, tipsContainer, employerSolutions, offeredSolutions } = data!;

  return (
    <div className={bemClassName()}>
      <BackgroundContainer type="beige">
        <div className={bemClassNames([bemClassName('breadcrumbs'), 'container'])}>
          <NavLink
            to="/dashboard"
            className={bemClassName('breadcrumb')}
          >
            {t('theme.breadcrumb-root')}
          </NavLink>
          <IconRender
            icon={Icon.chevronRight}
            color="var(--zk-blue)"
            height="24px"
            width="24px"
          />
          <span className={bemClassName('breadcrumb')}>{theme}</span>
        </div>
      </BackgroundContainer>
      <ThemeHeader {...header} />
      <div className={bemClassName('content')}>
        <ThemeTips {...tipsContainer} />
        {employerSolutions && <ThemeEmployerHelp {...employerSolutions} />}
        <ThemeZkHelp {...offeredSolutions} />
      </div>
      <BackgroundContainer type="white">
        <div className="container">
          <div className={bemClassName('footer')}>{t('theme.footer')}</div>
        </div>
      </BackgroundContainer>
    </div>
  );
};

export default ThemePage;
